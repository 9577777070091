<template>
  <div id="rebate-report">
    <div class="wrapper-main">
      <div class="wrapper-content">
        <AccountNumber @accountCallback="init"></AccountNumber>

        <div class="calendar-content-main clearfix"  v-if="updateTimeVisible">
          <DateRangePicker v-bind:startDate.sync="startDate" v-bind:endDate.sync="endDate"></DateRangePicker>
          <div class="right-box">
            <el-cascader
              expand-trigger="hover"
              :options="instrumentOptions"
              v-model="selectedInstrument"
              :show-all-levels="false"
            ></el-cascader>
            <loading-button class="btn-red" :callback="queryRebateReport" text="common.keys.UPDATE"></loading-button>
          </div>
        </div>

        <div class="main">
          <!-- 5个盒子 -->
          <div class="main-top">
            <div class="calendar-box">
              <ul class="clearfix">
                <li class="special">
                  <div class="top">
                    <p>{{ $t('report.rebate.others.rebate') }}</p>
                  </div>
                  <div class="bottom clearfix row">
                    <div class="bottom_left" >
                      <p v-if="fundsPermission">
                        <span>{{ currAccountCurrencyFilter }}</span>
                        {{ totalRebate | currency('') }}
                      </p>
                      <div v-else>***</div>
                      <span>{{ $t('report.rebate.others.totalRebate') }}</span>
                    </div>
                  </div>
                </li>

                <li v-for="(summary, index) in summaries" v-bind:key="index">
                  <div class="top">
                    <p>{{ summary.category }}</p>
                  </div>
                  <div class="bottom clearfix">
                    <div v-if="isValue(summary.volume)" class="bottom_left">
                      <p v-if="fundsPermission">{{ summary.volume | formatNumber }}</p>
                      <div v-else>***</div>
                      <span>{{ $t('report.rebate.others.lots') }}</span>
                    </div>
                    <div v-if="isValue(summary.commission)" class="bottom_right">
                      <p v-if="fundsPermission">
                        <span>{{ currAccountCurrencyFilter }}</span>
                        {{ summary.commission | currency('') }}
                      </p>
                      <div v-else>***</div>
                      <span>{{ $t('report.rebate.others.rebate') }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- 表格部分 -->
          <div class="main-bottom">
            <div class="content clearfix">
              <div class="content-box">
                <div class="conHeader">
                  <span>
                    {{ $t('report.rebate.others.rebate') }} {{ $t('common.keys.FROM') }}

                    <span class="blue">
                      {{ startDate | formatDate('DD/MM/YYYY') }} - {{ endDate | formatDate('DD/MM/YYYY') }} >>></span
                    >
                  </span>
                  <download-button 
                    class="button" 
                    :callback="downLoad" 
                    icon="el-icon-download"
                    :noPermission="!fundsPermission"
                  >
                  </download-button>
                </div>
                
                <div class="table-box">
                  <el-table
                    :data="displayData"
                    ref="summaryTable"
                    style="width: 100%;"
                    :default-sort="{ prop: 'totalrebate', order: 'descending' }"
                    @sort-change="sortChange"
                  >
                    <el-table-column
                      :label="$t('report.rebate.column.name')"
                      style="word-wrap: normal;"
                      min-width="100"
                    >
                      <template slot-scope="scope">
                        <a
                          @click.stop.prevent="
                            dialogVisible = true;
                            viewTradeDetail = { name: scope.row.name, mt4Account: scope.row.number };
                          "
                          data-testid="name"
                          >{{ scope.row.name }}</a
                        >
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="number"
                      :label="$t('report.rebate.column.accountNumber')"
                      min-width="120"
                    ></el-table-column>
                    <el-table-column :label="$t('report.rebate.column.accountType')" min-width="70">
                      <template slot-scope="scope">
                        {{ $config.accountTypeMaps[scope.row.type] }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      :label="$t('common.products.forex')"
                      sortable
                      sort-by="forex.rebate"
                      :sort-orders="['ascending', 'descending']"
                    >
                      <el-table-column :label="$t('report.rebate.column.volume')">
                        <template slot-scope="scope">
                          <span>{{ scope.row.forex.volume | formatNumber }}</span>
                        </template>
                      </el-table-column>

                      <el-table-column :label="$t('report.rebate.column.rebate')">
                        <template slot-scope="scope">
                          <span>{{ currAccountCurrencyFilter }}{{ scope.row.forex.rebate | currency('') }}</span>
                        </template>
                      </el-table-column>
                    </el-table-column>

                    <el-table-column
                      :label="$t('common.products.commodities')"
                      sortable
                      sort-by="commodities.rebate"
                      :sort-orders="['ascending', 'descending']"
                    >
                      <el-table-column :label="$t('report.rebate.column.volume')">
                        <template slot-scope="scope">
                          <span>{{ scope.row.commodities.volume | formatNumber }}</span>
                          <p style="border-right: 1px solid red;"></p>
                        </template>
                      </el-table-column>

                      <el-table-column :label="$t('report.rebate.column.rebate')">
                        <template slot-scope="scope">
                          <span>{{ currAccountCurrencyFilter }}{{ scope.row.commodities.rebate | currency('') }}</span>
                        </template>
                      </el-table-column>
                    </el-table-column>

                    <el-table-column
                      :label="$t('common.products.indices')"
                      sortable
                      sort-by="indices.rebate"
                      :sort-orders="['ascending', 'descending']"
                    >
                      <el-table-column :label="$t('report.rebate.column.volume')">
                        <template slot-scope="scope">
                          <span>{{ scope.row.indices.volume | formatNumber }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column :label="$t('report.rebate.column.rebate')">
                        <template slot-scope="scope">
                          <span>{{ currAccountCurrencyFilter }}{{ scope.row.indices.rebate | currency('') }}</span>
                        </template>
                      </el-table-column>
                    </el-table-column>

                    <el-table-column
                      :label="$t('common.products.crypto')"
                      sortable
                      sort-by="crypto.rebate"
                      :sort-orders="['ascending', 'descending']"
                    >
                      <el-table-column :label="$t('report.rebate.column.volume')">
                        <template slot-scope="scope">
                          <span>{{ scope.row.crypto.volume | formatNumber }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column :label="$t('report.rebate.column.rebate')">
                        <template slot-scope="scope">
                          <span>{{ currAccountCurrencyFilter }}{{ scope.row.crypto.rebate | currency('') }}</span>
                        </template>
                      </el-table-column>
                    </el-table-column>

                    <el-table-column
                      :label="$t('common.products.sharecfd')"
                      sortable
                      sort-by="sharecfd.rebate"
                      :sort-orders="['ascending', 'descending']"
                    >
                      <el-table-column :label="$t('report.rebate.column.volume')" min-width="100">
                        <template slot-scope="scope">
                          <span>{{ scope.row.sharecfd.volume | formatNumber }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column :label="$t('report.rebate.column.rebate')" min-width="70">
                        <template slot-scope="scope">
                          <span>{{ currAccountCurrencyFilter }}{{ scope.row.sharecfd.rebate | currency('') }}</span>
                        </template>
                      </el-table-column>
                    </el-table-column>

                    <el-table-column
                      :label="$t('common.products.bond')"
                      sortable
                      sort-by="bond.rebate"
                      :sort-orders="['ascending', 'descending']"
                    >
                      <el-table-column :label="$t('report.rebate.column.volume')" min-width="100">
                        <template slot-scope="scope">
                          <span>{{ scope.row.bond.volume | formatNumber }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column :label="$t('report.rebate.column.rebate')" min-width="70">
                        <template slot-scope="scope">
                          <span>{{ currAccountCurrencyFilter }}{{ scope.row.bond.rebate | currency('') }}</span>
                        </template>
                      </el-table-column>
                    </el-table-column>

                    <el-table-column
                      :label="$t('common.products.pamm')"
                      sortable
                      sort-by="pamm.rebate"
                      :sort-orders="['ascending', 'descending']"
                    >
                      <el-table-column :label="$t('report.rebate.column.rebate')" min-width="100">
                        <template slot-scope="scope">
                          <span>{{ currAccountCurrencyFilter }}{{ scope.row.pamm.rebate | currency('') }}</span>
                        </template>
                      </el-table-column>
                    </el-table-column>

                    <el-table-column
                      :label="$t('common.products.pamm')"
                      sortable
                      sort-by="pamm.rebate"
                      :sort-orders="['ascending', 'descending']"
                    >
                      <el-table-column :label="$t('report.rebate.column.rebate')" min-width="100">
                        <template slot-scope="scope">
                          <span>{{ currAccountCurrencyFilter }}{{ scope.row.pamm.rebate | currency('') }}</span>
                        </template>
                      </el-table-column>
                    </el-table-column>

                    <el-table-column
                      :label="$t('report.rebate.column.totalRebate')"
                      sortable
                      sort-by="totalrebate"
                      :sort-orders="['ascending', 'descending']"
                      min-width="110"
                    >
                      <template slot-scope="scope">
                        <span>{{ currAccountCurrencyFilter }}{{ scope.row.totalrebate | currency('') }}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <!-- 分页 -->
                <pagination v-bind:table-data="tableData" v-bind:display-data.sync="displayData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog :dialogVisible.sync="dialogVisible" :viewTradeDetail="viewTradeDetail"></Dialog>
    <downloadDialog v-if="downloadVisible" :downloadVisible.sync="downloadVisible" @closeDownloadDoalog="closeDownloadDoalog"  @onConfrim="onConfrim"></downloadDialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination';
import LoadingButton from '@/components/LoadingButton';
import DateRangePicker from '@/components/DateRangePicker';
import Dialog from '@/components/rebateReport/Dialog';
import AccountNumber from '@/components/form/AccountNumber';
import { apiRebate_report, apiGet_instruments } from '@/resource';
import DownloadButton from '@/components/download/DownloadButton';
import { downLoadExcelMode } from "@/util/downLoadExcelMode";
import downloadDialog from "@/components/download/Dialog";

export default {
  components: { Pagination, LoadingButton, DateRangePicker, Dialog, AccountNumber, DownloadButton, downloadDialog  },
  data() {
    return {
      downloadVisible: false,
      updateTimeVisible: true,
      startDate: '',
      endDate: '',
      userID: this.$store.state.common.CUID,
      dialogVisible: false,
      totalRebate: 0,
      summaries: [
        {
          category: this.$t('common.products.forex'),
          commission: 0,
          volume: 0
        },
        {
          category: this.$t('common.products.commodities'),
          commission: 0,
          volume: 0
        },
        {
          category: this.$t('common.products.indices'),
          commission: 0,
          volume: 0
        },
        {
          category: this.$t('common.products.crypto'),
          commission: 0,
          volume: 0
        },
        {
          category: this.$t('common.products.sharecfd'),
          commission: 0,
          volume: 0
        },
        {
          category: this.$t('common.products.bond'),
          commission: 0,
          volume: 0
        },
        {
          category: this.$t('common.products.pamm'),
          commission: 0
        }
      ],
      viewTradeDetail: { name: null, mt4Account: null },
      selectedInstrument: [null],
      instrumentOptions: [
        {
          value: null,
          label: this.$t('report.rebate.dropdown.allInstruments')
        },
        {
          value: 'forex',
          label: this.$t('common.products.forex'),
          children: []
        },
        {
          value: 'commodities',
          label: this.$t('common.products.commodities'),
          children: []
        },
        {
          value: 'indices',
          label: this.$t('common.products.indices'),
          children: []
        },
        {
          value: 'crypto',
          label: this.$t('common.products.crypto'),
          children: []
        },
        {
          value: 'sharecfd',
          label: this.$t('common.products.sharecfd'),
          children: []
        },
        {
          value: 'bond',
          label: this.$t('common.products.bond'),
          children: []
        }
      ],
      // 分页
      displayData: [],
      sorting: { column: 'totalrebate', order: 'descending' },
      tableData: [],
      currAccountCurrencyFilter: ''
    };
  },
  watch: {
    dataSourceId() {
      this.queryInstruments();
    }
  },
  methods: {
    closeDownloadDoalog(){
      this.updateTimeVisible = true;
      this.downloadVisible = false;
    },
    onConfrim(startDate, endDate, isUpdate){
      if(isUpdate) this.updateTimeVisible = false;
      this.startDate = startDate;
      this.endDate = endDate;
      this.queryRebateReport().then(async(res)=>{
        const downloadFile = await this.downLoadExcel()
        if(downloadFile) {
          this.$message({
            message: this.$t('ibReport.successInfo'),
            type: 'success'
          });
        }else {
          this.$message({
            message: '',
            type: 'error'
          });
        }
        if(isUpdate) this.closeDownloadDoalog()
      })
      
    },
    async downLoad(){
      // 间隔天数
      let days = this.$options.filters.formatDateDiff(this.startDate,  this.endDate);
      // 允许下载最大天数
      const MAX_DAYS = 90;
      if (days > MAX_DAYS) {
        //大于90天弹框 重新选择日期再下载 
        this.downloadVisible = true
        return Promise.resolve();
      }
      //在90天范围内直接下载excel
      this.onConfrim(this.startDate, this.endDate)
    },
    async downLoadExcel() {
      // 表名称
      const fileName = `${this.accountID}-${this.$t('report.title.rebateReport')}${this.timeFrame}`;
      // sheet 表头 
      // 入金
      const reportHeader = [
        'report.rebate.column.name',
        'report.rebate.column.accountNumber',
        'report.rebate.column.accountType',
        'common.products.forex-report.rebate.column.volume',
        'common.products.forex-report.rebate.column.rebate',
        'common.products.commodities-report.rebate.column.volume',
        'common.products.commodities-report.rebate.column.rebate',
        'common.products.indices-report.rebate.column.volume',
        'common.products.indices-report.rebate.column.rebate',
        'common.products.crypto-report.rebate.column.volume',
        'common.products.crypto-report.rebate.column.rebate',
        'common.products.sharecfd-report.rebate.column.volume',
        'common.products.sharecfd-report.rebate.column.rebate',
        'common.products.bond-report.rebate.column.volume',
        'common.products.bond-report.rebate.column.rebate',
        'common.products.pamm-report.rebate.column.rebate',
        'report.rebate.column.totalRebate',
      ].map((key) => {
        return key.indexOf('-') !== -1 ? `${this.$t(key.split('-')[0])} - ${this.$t(key.split('-')[1])}` : this.$t(key)
      } );
     
      // sheet名称
      const reportName = `${this.$t('report.title.rebateReport')}`

      const reportData = {
        sheetData: this.parseExcelData(this.tableData),
        sheetName: reportName.slice(0, 31),
        sheetHeader: reportHeader,
        columnWidths: [6, 6, 6, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10] //宽度
      }
     
      let datas =  [reportData];
      if (datas.length) return await downLoadExcelMode(fileName, datas);
    },
    parseExcelData(traderTableData, type) {
      return traderTableData.map(item => {
        let {
          name,
          number,
          type: itemType,
          forex,
          commodities,
          indices,
          crypto,
          sharecfd,
          bond,
          pamm,
          totalrebate,
        } = item;
        const { volume: forexVolume, rebate: forexRebate } = forex;
        const { volume: commoditiesVolume, rebate: commoditiesRebate } = commodities;
        const { volume: indicesVolume, rebate: indicesRebate } = indices;
        const { volume: cryptoVolume, rebate: cryptoRebate } = crypto;
        const { volume: sharecfdVolume, rebate: sharecfdRebate } = sharecfd;
        const { volume: bondVolume, rebate: bondRebate } = bond;
        const { rebate: pammRebate } = pamm;
        return {
          name,
          number,
          type: this.$config.accountTypeMaps[itemType],
          forexVolume: this.$options.filters.formatNumber(forexVolume),
          forexRebate: this.currAccountCurrencyFilter + forexRebate,
          commoditiesVolume: this.$options.filters.formatNumber(commoditiesVolume),
          commoditiesRebate: this.currAccountCurrencyFilter + commoditiesRebate,
          indicesVolume: this.$options.filters.formatNumber(indicesVolume),
          indicesRebate: this.currAccountCurrencyFilter + indicesRebate,
          cryptoVolume: this.$options.filters.formatNumber(cryptoVolume),
          cryptoRebate: this.currAccountCurrencyFilter + cryptoRebate,
          sharecfdVolume: this.$options.filters.formatNumber(sharecfdVolume),
          sharecfdRebate: this.currAccountCurrencyFilter + sharecfdRebate,
          bondVolume: this.$options.filters.formatNumber(bondVolume),
          bondRebate: this.currAccountCurrencyFilter + bondRebate,
          pammRebate: this.currAccountCurrencyFilter + pammRebate,
          totalrebate: this.currAccountCurrencyFilter + totalrebate
        };
      });
    },
    queryRebateReport() {
      if (this.accountID === null || this.accountID.length < 1) {
        return Promise.resolve();
      }

      return apiRebate_report({
        account: this.accountID,
        startDate: this.startDate,
        endDate: this.endDate,
        symbol:
          Array.isArray(this.selectedInstrument) && this.selectedInstrument.length == 2
            ? this.selectedInstrument[1]
            : null
      }).then(response => {
        this.totalRebate = response.data.total;

        let keyMap = { 'share cfd': 'sharecfd' };
        this.tableData = response.data.payerSummaries.map(item => {
          var objs = Object.keys(item).reduce((newData, key) => {
            let newKey = keyMap[key] || key;
            newData[newKey] = item[key];
            return newData;
          }, {});
          return objs;
        });

        this.currAccountCurrencyFilter = this.$options.filters.currencySymbol(this.currAccountCurrency);

        this.sortData();
        this.summaries.forEach(s => {
          if (s.commission) s.commission = 0;
          if (s.volume) s.volume = 0;
          for (let key in response.data.summaries) {
            let temp = key.toLowerCase().replace(/\s+/g, '');
            temp = this.$t('common.products.' + temp);
            if (temp === s.category) {
              s.commission = response.data.summaries[key].commission;
              s.volume = response.data.summaries[key].volume;
              break;
            }
          }
        });
      }, this.$handleError);
    },
    sortData() {
      this.sorting.column = this.sorting.column || 'totalrebate';
      this.sorting.order = this.sorting.order || 'descending';
      this.tableData.sort((a1, a2) => {
        if (this.sorting.column == 'totalrebate') {
          return this.sorting.order == 'descending'
            ? a2['totalrebate'] - a1['totalrebate']
            : a1['totalrebate'] - a2['totalrebate'];
        }
        if (this.sorting.column == 'forex.rebate') {
          return this.sorting.order == 'descending'
            ? a2['forex']['rebate'] - a1['forex']['rebate']
            : a1['forex']['rebate'] - a2['forex']['rebate'];
        }
        if (this.sorting.column == 'commodities.rebate') {
          return this.sorting.order == 'descending'
            ? a2['commodities']['rebate'] - a1['commodities']['rebate']
            : a1['commodities']['rebate'] - a2['commodities']['rebate'];
        }
        if (this.sorting.column == 'indicies.rebate') {
          return this.sorting.order == 'descending'
            ? a2['indicies']['rebate'] - a1['indicies']['rebate']
            : a1['indicies']['rebate'] - a2['indicies']['rebate'];
        }
        if (this.sorting.column == 'crypto.rebate') {
          return this.sorting.order == 'descending'
            ? a2['crypto']['rebate'] - a1['crypto']['rebate']
            : a1['crypto']['rebate'] - a2['crypto']['rebate'];
        }
        if (this.sorting.column == 'sharecfd.rebate') {
          return this.sorting.order == 'descending'
            ? a2['sharecfd']['rebate'] - a1['sharecfd']['rebate']
            : a1['sharecfd']['rebate'] - a2['sharecfd']['rebate'];
        }
        if (this.sorting.column == 'bond.rebate') {
          return this.sorting.order == 'descending'
            ? a2['bond']['rebate'] - a1['bond']['rebate']
            : a1['bond']['rebate'] - a2['bond']['rebate'];
        }
      });
    },
    queryInstruments() {
      if (!this.dataSourceId) {
        return;
      }

      //恢復預設
      this.instrumentOptions.forEach(i => {
        if (Array.isArray(i.children)) i.children = [];
      });
      apiGet_instruments({ dataSourceId: this.dataSourceId }).then(response => {
        for (let k in response.data) {
          let instrument = this.instrumentOptions.find(i => i.value == k.replace(/\s+/g, ''));
          if (instrument && Array.isArray(instrument.children)) {
            instrument.children = response.data[k].sort().map(prod => {
              return { value: prod, label: prod };
            });
          }
        }
      }, this.$handleError);
    },
    sortChange(column) {
      if (!column.column) {
        return;
      }
      this.sorting = { column: column.column.sortBy, order: column.order };
      this.sortData();
    },
    init() {
      this.queryInstruments();
      this.queryRebateReport();
    },
    isValue(el) {
      return el !== undefined && el !== null;
    }
  },
  computed: {
    timeFrame() {
      return `(${this.$options.filters.formatDate(this.startDate, 'DD-MM-YYYY')}~${this.$options.filters.formatDate(this.endDate, 'DD-MM-YYYY')})`
    },
    fundsPermission() {
      return this.$store.state.common.permissionIds.includes(3) || !this.$store.state.common.isSubUser;
    },
    accountID() {
      return this.$store.state.behavior.AID;
    },
    currAccountCurrency() {
      return this.$store.state.behavior.CUR;
    },
    dataSourceId() {
      return this.$store.state.behavior.dataSourceId;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/rebateReport.scss';
</style>

